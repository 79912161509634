import React from 'react';
import TitleHeader from '../TitleHeader';
import { FormattedMessage } from 'react-intl';

const KompleksowaOpiekaStom = () => {
	return (
		<div>
			<TitleHeader title={<FormattedMessage id="menu.services.comprehensiveDentalCare" />} />

			<div className="grid-two-column content">
				<FormattedMessage id="services.comprehensiveDentalCare.takingCareOfYourHealth" tagName="h2" />
				<FormattedMessage
					id="services.comprehensiveDentalCare.takingCareOfYourHealth.description"
					tagName="p"
				/>

				<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention" tagName="h2" />
				<div>
					<FormattedMessage
						id="services.comprehensiveDentalCare.treatmentAndPrevention.description"
						tagName="p"
					/>
					<ul className="list--disc">
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li1">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li2">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li3">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li4">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li5">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li11">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li6">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li10">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li7">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li8">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
						<li>
							<FormattedMessage id="services.comprehensiveDentalCare.treatmentAndPrevention.description.li9">
								{(msg) => <strong className="brown">{msg}</strong>}
							</FormattedMessage>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
export default KompleksowaOpiekaStom;
