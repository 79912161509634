import React from 'react';
import KompleksowaOpiekaStom from '../../components/Uslugi/KompleksowaOpieka';
import Layout from '../../layout/pl';

const KompleksowaOpiekaStomComp = (props) => {
	return (
		<Layout {...props}>
			<KompleksowaOpiekaStom />
		</Layout>
	);
};

export default KompleksowaOpiekaStomComp;
